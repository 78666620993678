import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../images/anitalogo-removebg.png';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-black border-t-4 border-black py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-12 md:py-12 border-t border-black lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-black p-2 bg-gray-100 text-gray-600 text-center rounded-lg xl:w-60 mx-auto">
                            <img src={logo}/>
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="col-span-8 md:col-span-8 lg:col-span-2 ml-2 mx-auto">
                        <h6 className="text-[#013289] text-xl font-bold text-gray-200 mb-8">Links</h6>
                        <ul className="text-md">
                        <li className="mb-4">
                          <HashLink className="px-4 font-extrabold text-gray-200 hover:text-red-200" smooth to="/#the-meme">
                            The MEME
                          </HashLink>
                        </li>
                        <li className="mb-4">
                          <HashLink className="px-4 font-extrabold text-gray-200 hover:text-red-200" smooth to="/#about">
                            About Us
                          </HashLink>
                        </li>
                        <li className="mb-4">
                          <HashLink className="px-4 font-extrabold text-gray-200 hover:text-red-200" smooth to="/#roadmap">
                            Roadmap
                          </HashLink>
                        </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold text-gray-200">
                        <div className="text-xl mb-6">
                            Social Media Links
                        </div>

                                <div className="text-md font-medium mb-6">
                                    Follow us on social media.
                                </div>
                        <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                      <a href="https://twitter.com/AnitaMaxWynnXYZ" className="flex justify-center items-center text-black hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Telegram">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg>
                                      </a>
                                    </li>

                                </ul>
                            </div>
                    </div>

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">

                    <div className="text-sm text-gray-200 font-semibold py-1">
                    {new Date().getFullYear()}{"  "}
                    anitamaxwynn.xyz WAGMI $ANITA
                    </div>
                </div>
                </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;
