import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-200 hover:text-red-200" smooth to="/#the-meme">
              The MEME
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-200 hover:text-red-200" smooth to="/#about">
                About Us
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-200 hover:text-red-200" smooth to="/#roadmap">
                Roadmap
            </HashLink>

            <Link className="font-semibold text-black bg-red-300 hover:bg-red-200 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/merch">
                Merch
            </Link>
        </>
    )
}

export default NavLinks;
