import React from 'react';
import './Roadmap.css';

const Portfolio = () => {
    return (
        <>
            <div className="bg-black py-12" id='roadmap'>
                <h2 className="my-4 py-4 text-center text-3xl text-red-200 uppercase font-bold mb-6">The Roadmap</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-red-200 mb-12'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5 mb-32">
                        <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:scale-105 rounded-lg shadow-2xl shadow-red-100 p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg text-gray-400 md:text-2xl text-center mb-4 h-12">Q1 2024</h4>
                                <ul className="ml-6 mr-6 list-disc word-spacing-ul">
                                    <li className="text-lg font-semibold leading-5 mb-3">Launch the $ANITA token!</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Build our community.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Find suppliers/manufacturers for our clothing and accessories.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Generate hype around $ANITA.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Apply to get listed on the Jupiter aggregator.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="bg-zinc-800 transition-all ease-in-out duration-400 overflow-hidden text-gray-300 hover:scale-105 rounded-lg shadow-2xl shadow-red-100 p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg text-gray-400 md:text-2xl text-center mb-4 h-12">Q2 2024</h4>
                                <ul className="ml-6 mr-6 list-disc word-spacing-ul">
                                    <li className="text-lg font-semibold leading-5 mb-3">$ANITA merchandise launch!</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Mass marketing efforts.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Generate volume to get listed on coinmarketcap, coingecko, etc.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Screenshots for the NFT airdrop at random intervals from the start of Q2 until the Q3 airdrop.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Apply to get listed on centralized exchanges.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:scale-105 rounded-lg shadow-2xl shadow-red-100 p-3">
                            <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg text-gray-400 md:text-2xl text-center mb-4 h-12">Q3 2024</h4>
                              <ul className="ml-6 mr-6 list-disc word-spacing-ul">
                                <li className="text-lg font-semibold leading-5 mb-3">Release an announcement about up-coming NFT drop and future P2E game.</li>
                                <li className="text-lg font-semibold leading-5 mb-3">Change merch sale marketing fund to fully contribute to the NFT game treasury.</li>
                                <li className="text-lg font-semibold leading-5 mb-3">Launch the Anita Max Wynn Club NFTs, with 20% airdropped to holders based on their history with $ANITA. 30% for pre-sale available to $ANITA holders who do not qualify for the airdrop, and 50% public launch.</li>
                              </ul>
                            </div>
                        </div>

                        <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:scale-105 rounded-lg shadow-2xl shadow-red-100 p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg text-gray-400 md:text-2xl text-center mb-4 h-12">Q4 2024</h4>
                                <ul className="ml-6 mr-6 list-disc word-spacing-ul">
                                    <li className="text-lg font-semibold leading-5 mb-3">Release the $ANITA P2E game, playable via the Anita Max Wynn Club NFTs.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Implement burning and merging of certain NFTs.</li>
                                    <li className="text-lg font-semibold leading-5 mb-3">Pay out top players monthly via the game treasury.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
