import React from 'react';
import './Intro.css';

const Intro = () => {
    return (
        <>
            <div className="bg-gradient-to-b from-black from-10% via-zinc-800 via-30% to-black to-90% py-12 m-auto max-w-auto p-2 md:p-12 h-5/6 relative" id='the-meme'>
                <div className="relative z-10 grid lg:grid-cols-3 gap-4 py-8 justify-between lg:text-left" data-aos="fade-up">
                    {/* Column 1 - Video 1 */}
                    <div className="sm:hidden text-3xl font-bold text-center -mb-12 text-gray-100">The MEME</div>
                    <div className="lg:col-span-1 flex flex-col justify-center items-center space-y-4 mr-6">
                        <div className="rounded-t overflow-hidden aspect-w-9 aspect-h-16 max-w-full max-h-full w-3/4">
                            <video controls className="w-full h-full object-cover rounded-lg hide-on-small-screen" preload="metadata">
                                <source src="/videos/video3.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img src="/videos/vertical-thumbnail.jpg" alt="Vertical Video Thumbnail" className="hidden" />
                        </div>
                    </div>

                    {/* Column 2 - Video 3 */}
                    <div className="lg:col-span-1 sm:max-md:mb-0 mb-16">
                        <div className="rounded-t overflow-hidden aspect-w-9 aspect-h-16 max-w-full max-h-full w-3/4">
                            <video controls className="w-full h-full object-cover rounded-lg hide-on-small-screen" preload="metadata">
                                <source src="/videos/video1.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img src="/videos/vertical-thumbnail.jpg" alt="Vertical Video Thumbnail" className="hidden" />
                        </div>
                    </div>

                    {/* Column 3 - Text and Video 2 */}
                    <div className="flex flex-col">
                        <div className="sm:max-md:text-center sm:max-md:justify-center lg:text-center lg:my-12 lg:justify-end w-full px-8 lg:px-0 lg:w-3/4">
                            <h3 className="text-2xl font-semibold leading-snug tracking-wide lg:mt-8 text-gray-100">
                                The viral meme originated from Drake's Kick stream on December 17th, 2023. When someone handed Drake a funny hat with a unique character design and the words "Anita Max Wynn". Drake proceeded to say that it was his alter-ego "Anita Max Wynn", which can also mean "I need a max win" (referring to the act of getting the maximum win possible in a single slot machine spin).
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
