import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import NavLinks from '../Navbar/NavLinks';

const NavBar = () => {
  const [top, setTop] = useState(true);
  const [isOpen, setisOpen] = useState(false);

  function handleClick() {
    setisOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <nav
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-0 ${
        !top && 'bg-zinc-800 shadow-lg'
      }`}
    >
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
          <HashLink smooth to="/#home">
            <h1 className="mb-0 md:text-5xl text-3xl font-bold" style={{ color: "#F49ABD" }}>
              $ANITA
            </h1>
          </HashLink>
        </div>

        <div className="group flex flex-col items-center">
          {/* Mobile Menu Toggle Button */}
          <button
            className="p-2 rounded-lg lg:hidden text-blue-900"
            onClick={handleClick}
          >
            <svg className="h-6 w-10 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>

          {/* Mobile Menu */}
          <div
            className={`${
              isOpen ? 'block' : 'hidden'
            } lg:hidden fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-4 bg-black rounded-lg shadow-xl top-14`}
          >
            <div className="flex flex-col space-y-2">
              <NavLinks />
            </div>
          </div>

          {/* Desktop Navigation Links */}
          <div className="hidden space-x-4 lg:inline-block p-1">
            <NavLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
