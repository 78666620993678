import React from 'react';
import img from '../images/firstmover.png';
import img2 from '../images/community.png';
import img4 from '../images/potential.png';
import img3 from '../images/anitalogo-removebg.png';

const Services = () => {

    return (
        <div id="about" className="bg-black py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-gray-100 uppercase font-bold mb-6">about us</h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-red-200 mb-6'></div>
                        </div>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-100 hover:bg-zinc-700 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.1] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">First Mover's Advantage</h2>
                                    <p className="text-base font-medium tracking-tight">
                                        We started building as soon as the viral meme happened. Sure, some quick cash grabs and scams beat us to launch but those projects have already rugged or are on their way to. We're also in it for the long-term, we think $ANITA has the potential to be the next viral meme token.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-100 hover:bg-zinc-700 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.1] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Community Driven</h2>
                                    <p className="text-base font-medium tracking-tight">
                                        We want the community to be completely involved and passionate about the project. We know that community is everything in crypto, whether you're a meme token, utility project or something else; Community is vital. As such, we want to let the community decide where we spend the marketing fund through polls and discussion on Telegram.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-100 hover:bg-zinc-700 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.1] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Clean Branding</h2>
                                    <p className="text-base font-medium tracking-tight">
                                        With the dope character design already made for Anita Max Wynn, we've added a super clean and sleek website. As well as upscaling all the images to make them as crisp as possible. $ANITA also rolls off the tongue smoothly and is easy to remember!
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-100 hover:bg-zinc-700 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.1] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Unmeasurable Potential</h2>
                                    <p className="text-base font-medium tracking-tight">
                                        Built off of inspiration from the now super viral "Anita Max Wynn" meme that Drake started. We believe that the popularity of the meme and the $ANITA token will only continue to grow. Especially with streamers reacting to the clips, new tiktoks being posted regularly, and the daily search traffic from people trying to figure out what it is!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                            </div>
                            <h3 className="text-3xl  text-gray-100
                            font-bold">Active <span className='font-black'>Twitter/X.com</span></h3>
                            <div>
                                <p className='my-3 text-2xl text-gray-400 font-semibold'>
                                    Consistent activity on social platforms in the long run will help us grow massively. That's why we have a team member that focuses on our telegram community and twitter social presence!
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                            </div>
                            <h3 className="text-3xl  text-gray-100 font-bold">Community <span className='font-black'>First</span></h3>
                            <div>
                                <p className='my-3 text-2xl text-gray-400 font-semibold'>Our goal is to build a great community that can help us shill, spread the word, share funny memes, tweet with us and more. Hopefully, this will help us grow and get listed on exchanges faster!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;
