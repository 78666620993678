import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import image1 from '../images/image5.PNG';
import image2 from '../images/image2.PNG';
import image3 from '../images/image4.PNG';
import image4 from '../images/image3.PNG';
import image5 from '../images/image1.PNG';

const Merch = () => {
  const [sliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="bg-black py-12 lg:py-36">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <div className="flex items-center justify-center mt-36 mb-28">
            <Slider {...sliderSettings} className="w-5/6">
              <div className="space-x-0.5 space-y-0.5 mx-auto">
                <img
                  src={image1}
                  alt="Image 1"
                  className="w-full h-full object-cover rounded-lg ml-6 w-5/6"
                />
              </div>
              <div className="space-x-0.5 space-y-0.5 mx-auto">
                <img
                  src={image2}
                  alt="Image 2"
                  className="w-full h-full object-cover rounded-lg ml-6 w-5/6"
                />
              </div>
              <div className="space-x-0.5 space-y-0.5 mx-auto">
                <img
                  src={image3}
                  alt="Image 3"
                  className="w-full h-full object-cover rounded-lg ml-6 w-5/6"
                />
              </div>
              <div className="space-x-0.5 space-y-0.5 mx-auto">
                <img
                  src={image4}
                  alt="Image 4"
                  className="w-full h-full object-cover rounded-lg ml-6 w-5/6"
                />
              </div>
              <div className="space-x-0.5 space-y-0.5 mx-auto">
                <img
                  src={image5}
                  alt="Image 5"
                  className="w-full h-full object-cover rounded-lg ml-6 w-5/6"
                />
              </div>
              {/* Add more images as needed */}
            </Slider>
          </div>
        </div>
        <div className="mt-4 mx-12 text-center">
          <div className="text-2xl font-semibold leading-snug tracking-wide mb-8 text-gray-100">
            ALL merch sales will go directly to the $ANITA marketing fund/treasury. Holders with a certain amount of $ANITA tokens will get a 20% discount when buying merch!
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Merch;
