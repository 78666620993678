import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import logo from '../images/anitalogo-removebg.png';
import './Hero.css';

const Hero = () => {
  return (
    <>
      <div className="bg-black py-12" id='home'>
        <div>
          <NavBar />
        </div>

        <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
          <div className="flex lg:flex-row flex-col py-8 justify-between text-center lg:text-left">
            <div className="w-full lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
              <h1 className="mb-5 lg:text-9xl text-5xl sm:text-6xl font-bold" style={{
                background: 'linear-gradient(185deg, #E1B9CF, #F49ABD)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
                Meet $ANITA
              </h1>

              <div className="text-2xl font-semibold leading-snug tracking-wide mb-5 text-gray-100">
                The newest meme token in town! Inspired by the viral Drake alter-ego Anita Max Wynn, which has taken over the internet by storm. Currently, it's a super fresh meme BUT... with time, the saying "Anita Max Wynn" and our corresponding token $ANITA could become a legendary meme-token surpassing even the likes of $BONK and many others! All in good faith though, we love our friends at $BONK!
              </div>

              <div className="flex items-center text-2xl font-semibold leading-snug tracking-wide mt-6 mb-14 text-gray-100">
               <div className="telegram-button-container">
                <a
                  href="" // Replace with link
                  className="text-black bg-red-200 hover:bg-red-100 inline-flex items-center justify-center ml-8 mb-4 px-6 py-3 text-lg shadow-xl rounded-2xl max-w-full"
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Recommended for security reasons when using target="_blank"
                >
                  CA coming soon!
                  <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                </a>

                  <a
                    href="https://t.me/anita_token" // Replace with your actual Telegram link
                    className="text-black bg-red-200 hover:bg-red-100 inline-flex items-center justify-center ml-8 mb-4 px-6 py-3 text-lg shadow-xl rounded-2xl max-w-full"
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Recommended for security reasons when using target="_blank"
                    >
                    Join our Telegram!
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
              <div className="glow-effect">
                <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={logo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
